<template>
    <div>
        <div v-if="!addOrEditReady">
            <LoadingSpinner class="col-12" />
        </div>

        <div v-if="addOrEditReady">
            <form @submit.prevent="checkForm">
                <input type="hidden" v-model="form.horse_id">

                <div class="row">
                    <div class="col-12" v-if="form_message !== ''">
                        <ErrorAlert :messageI18n="form_message" />
                    </div>

                    <div class="col-12" v-if="!tiershorse_id">
                        <div class="form-group" v-if="!horse">
                            <label for="horses" class="col-form-label">{{ $t("horse.horse") }} *</label>
                            <e-select
                                    v-model="form.horses"
                                    id="horses"
                                    label="name"
                                    :placeholder="labelTitleHorse"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="list_horses"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                        </div>

                        <div class="form-group" v-else>
                            <label for="tiers" class="col-form-label">{{ $t("tiers.tier") }} *</label>
                            <e-select
                                id="tiers"
                                track-by="name"
                                label="name"
                                :placeholder="labelTitleTiers"
                                :deselectLabel="deselectLabel"
                                :selectedLabel="selectedLabel"
                                :selectLabel="enter_to_select"
                                :options="tiers_options"
                                :searchable="true"
                                :show-labels="false"
                                @select="onTierSelected"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>

                    <LoadingSpinner v-if="loadingStep1" class="col-12" />

                    <template v-if="step >= 2">
                        <div class="col-12">
                            <template v-for="(tier, index) in form.tiers">
                                <div class="row" :key="tier.tiers.tiers_id">
                                    <div class="col-11">
                                        {{ tier.tiers.tiers_rs }}
                                    </div>
                                    <div class="col-1" v-if="!tier.tiers_horse_part.tiershorsepart_id && horse">
                                        <div @click="removeTier(tier.tiers.tiers_id)">
                                            <a class="btn close">×</a>
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="form-group">
                                            <label :for="`propriete_${index}`" class="col-form-label">{{ $t("tiers.poucentage_propriete") }} *</label>

                                            <input type="text" :id="`propriete_${index}`" class="form-control" v-model="tier.tiers_horse_part.tiershorsepart_propriete">
                                        </div>
                                    </div>

                                    <div class="col-3" v-if="hasFacturationAccess">
                                        <div class="form-group">
                                            <label :for="`pensions_${index}`" class="col-form-label">{{ $t("tiers.poucentage_pensions") }} *</label>

                                            <input type="text" :id="`pensions_${index}`" class="form-control" v-model="tier.tiers_horse_part.tiershorsepart_pension">
                                        </div>
                                    </div>

                                    <div class="col-3" v-if="hasFacturationAccess">
                                        <div class="form-group">
                                            <label :for="`frais_${index}`" class="col-form-label">{{ $t("tiers.poucentage_frais") }} *</label>

                                            <input type="text" :id="`frais_${index}`" class="form-control" v-model="tier.tiers_horse_part.tiershorsepart_frais">
                                        </div>
                                    </div>

                                    <div class="col-3" v-if="hasSaillieAccess">
                                        <div class="form-group">
                                            <label :for="`contract_${index}`" class="col-form-label">{{ $t("tiers.poucentage_contract") }} *</label>
                                            
                                            <input type="text" :id="`contract_${index}`" class="form-control" v-model="tier.tiers_horse_part.tiershorsepart_contract">
                                        </div>
                                    </div>

                                    <div class="col-3" v-if="hasTrainerAccess">
                                        <div class="form-group">
                                            <label :for="`contract_${index}`" class="col-form-label">{{ $t("tiers.poucentage_entraineur") }}</label>

                                            <input type="text" :id="`contract_${index}`" class="form-control" v-model="tier.tiers_horse_part.tiershorsepart_entraineur">
                                        </div>
                                    </div>

                                    <div class="col-3" v-if="hasTrainerAccess">
                                        <div class="form-group">
                                            <label :for="`contract_${index}`" class="col-form-label">{{ $t("tiers.poucentage_jockey") }}</label>

                                            <input type="text" :id="`contract_${index}`" class="form-control" v-model="tier.tiers_horse_part.tiershorsepart_jockey">
                                        </div>
                                    </div>

                                </div>

                                <div class="row"  :key="`dps_${tier.tiers.tiers_id}`">
                                    <div class="col-auto">
                                        <b-form-checkbox :id="`dps_${index}`"  v-model="tier_dps" :value="index" :unchecked-value="-index-1"><label >{{ $t("monte.tier_etalonnier") }}</label></b-form-checkbox>
                                    </div>
                                    <div class="col-auto">
                                        <b-form-checkbox :id="`lieu_${index}`" v-model="tier_stationnement" :value="index" :unchecked-value="-index-1"><label >{{ $t("mouvement.residence") }}</label></b-form-checkbox>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="date" class="col-form-label">{{ $t("tiers.date_effet") }} *</label>
                                <e-datepicker id="date" v-model="form.date"></e-datepicker>
                            </div>
                        </div>

                        <div v-if="ask_confirmation_pension" class="col-12">
                            <div class="alert alert-warning">
                                {{ $t('tiers.pension_100') }}
                                <a href="#" @click.prevent="confirmPension">{{ $t('tiers.confirmer') }}</a>
                            </div>
                        </div>
                    </template>
                </div>
            </form>
            <HorseAjout v-if="add_horse" from="tiers" :horse_id.sync="horse_id_added" :horseToAddName="horseToAddName" :horseSexeMandatory="horseSexeMandatory" />
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import HorseMixin from "@/mixins/Horse.js"
    import TiersMixin from "@/mixins/Tiers.js"
    import MouvementsMixin from "@/mixins/Mouvements.js"

    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35'
    import ErrorAlert from 'GroomyRoot/components/Alert/ErrorAlert'

    import _isEmpty from 'lodash/isEmpty'

    export default {
        name: "TiersHorseAjout",
        props: ['tiershorse_id', 'tiers_horse', 'disableButton', 'addOrEditReady', 'tiers', 'horse', 'horseToAddName', 'horseSexeMandatory'],
        mixins: [Navigation, Tools, TiersMixin, HorseMixin, MouvementsMixin],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                selectePart: false,
                labelTitleHorse: this.getTrad("tiers.search_horse"),
                list_horses: [],

                labelTitleTiers: this.getTrad("tiers.search_tiers"),
                list_tiers: [],
                tiers_options: [],

                loadingStep1: false,
                step: 1,
                form_message: "",
                form: {
                    horse_id: null,
                    date: new Date(),
                    horses: {},
                    tiers : [],
                    selected_tiers : {}
                },
                default_form: {
                    horse_id: null,
                    date: new Date(),
                    horses: {},
                    tiers : [],
                    selected_tiers : {}
                },
                tier_dps: 0,
                tier_stationnement: null,
                add_horse: false,
                horse_id_added: null,
                ask_confirmation_pension: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.form_message = ""
                this.$emit('update:addOrEditReady', false)
                this.setDisabledButton(false)
                this.step = 1

                if(this.horseToAddName != "" && this.horseToAddName != null && this.horse_id_added === null){
                    this.form.horses = {}
                    this.setDisabledButton(true)
                    this.add_horse = true
                }

                this.form = this.default_form

                let tierhorse = {}
                if(this.tiers_horse) {
                    tierhorse = this.tiers_horse.find(tierhorse => tierhorse.tiershorse_id == this.tiershorse_id)
                }

                if(this.tiershorse_id) {
                    this.step = 2
                    if(tierhorse) {
                        if(!this.horse) {
                            if(Object.keys(tierhorse.horse).length > 0) {
                                const tiers = await this.loadTiersCurrentPartComponent(tierhorse.horse.horse_id)
                                if(tiers) {
                                    this.form.horse_id = tierhorse.horse.horse_id
                                    this.form.tiers = tiers.tiers_horse
                                } else {
                                    this.form_message = "error.IK"
                                    this.step = 1
                                    this.setDisabledButton(true)
                                }
                            } else {
                                this.form_message = "error.IK"
                                this.step = 1
                                this.setDisabledButton(true)
                            }
                        } else {
                            const tiers = await this.loadTiersCurrentPartComponent(this.horse.horse_id)

                            if(tiers) {
                                this.form.horse_id = this.horse.horse_id
                                this.form.tiers = tiers.tiers_horse
                            } else {
                                this.form_message = "error.IK"
                                this.step = 1
                                this.setDisabledButton(true)
                            }
                        }
                        this.tier_dps = this.form.tiers.findIndex(tier => tier.tiershorse_dps == 1)

                        if(this.form.horse_id){
                            const tiers_lieu = await this.getHorseLieuStationnement(this.form.horse_id)
                            if(tiers_lieu) {
                                this.tier_stationnement = this.form.tiers.findIndex(tier => tier.tiers.tiers_id == tiers_lieu.tiers_id)
                            }
                        }
                    } else {
                        this.form_message = "error.IK"
                        this.step = 1
                        this.setDisabledButton(true)
                    }
                } else {
                    if(!this.horse) {
                        let horses = await this.getHorses()
                        if(this.horseSexeMandatory){
                            horses = horses.filter(horse => this.horseSexeMandatory.includes(horse.horse_sexe))
                        }
                        if(Object.keys(horses).length > 0) {
                            horses.forEach((horse) => {
                                
                                if(this.tiers_horse) {
                                    tierhorse = this.tiers_horse.filter(tierhorse => tierhorse.horse.horse_id == horse.horse_id)[0]
                                    if(!tierhorse) {
                                        this.list_horses.push({name: horse.horse_nom, id: horse.horse_id })
                                    }
                                } else {
                                    this.list_horses.push({name: horse.horse_nom, id: horse.horse_id })
                                }
                            })
                            this.list_horses.sort((a, b) => ((a.name ? a.name.trim() : a) > (b.name ? b.name.trim() : b)) ? 1 : -1)
                            this.list_horses.unshift({name: this.getTrad("tiers.add_horse"), id: 0})

                            if(this.horse_id_added) {
                                this.form.horses = this.list_horses.find(h => h.id === this.horse_id_added)
                                this.changeHorse(this.form.horses)
                            }
                        } else {
                            this.form_message = "error.NH"
                        }
                    } else {
                        const tiers = await this.loadTiers()
                        if(Object.keys(tiers).length > 0) {
                            this.list_tiers = tiers
                            this.load_tiers_options()
                        } else {
                            this.form_message = "error.NT"
                        }
                    }

                    this.setDisabledButton(true)
                }

                this.$emit('update:addOrEditReady', true)
            },
            setDisabledButton: async function(disabled) {
                this.$emit('update:disableButton', disabled)
            },
            changeHorse: async function(newval) {
                const horse_id = newval.id
                if(horse_id) {
                    if(this.tiers){
                        this.add_horse = false
                        this.step = 1
                        this.loadingStep1 = true
                        this.setDisabledButton(true)

                        let tiers = await this.loadTiersCurrentPartComponent(horse_id)

                        if(tiers) {
                            this.form.horse_id = horse_id

                            tiers.tiers_horse.push({
                                tiershorse_id: null,
                                tiers: {
                                    tiers_id: this.tiers.tiers_id,
                                    tiers_rs: this.tiers.tiers_rs
                                },
                                tiers_horse_part: this.getDefaultTierHorsePart(tiers.tiers_horse),
                            })
                            this.form.tiers = tiers.tiers_horse
                            this.step = 2
                            this.setDisabledButton(false)
                        } else {
                            this.form_message = "error.IK"
                        }

                        this.loadingStep1 = false
                    } else {
                        this.step = 1
                        this.add_horse = false
                        this.setDisabledButton(false)
                    }
                }else if(horse_id == 0) {
                        this.step = 1
                        this.form.horses = {}
                        this.setDisabledButton(true)
                        this.add_horse = true
                    }    
            },
            removeTier(tiers_id) {
                this.form.tiers = this.form.tiers.filter((tier_horse) => {
                    return tier_horse.tiers.tiers_id !== tiers_id
                })
                this.load_tiers_options()
            },
            onTierSelected(option) {
                this.form.selected_tiers = null
                this.addTier(option.id)
            },
            addTier: async function(tiers_id) {
                const horse_id = this.horse.horse_id

                if(horse_id && horse_id != 0) {
                    const tier = this.tiers_options.filter(tier => tier.id == tiers_id)[0]
                    if(tier) {
                        this.step = 1
                        this.loadingStep1 = true
                        this.setDisabledButton(true)

                        if (_isEmpty(this.form.tiers)) {
                            const tiers = await this.loadTiersCurrentPartComponent(horse_id)

                            if (!tiers) {
                                this.form_message = "error.IK"
                                return
                            }

                            this.form.tiers = tiers.tiers_horse
                        }

                        this.form.horse_id = horse_id

                        // Ajout d'un nouveau tier
                        this.form.tiers.push({
                            tiershorse_id: null,
                            tiers: {
                                tiers_id: tier.id,
                                tiers_rs: tier.name
                            },
                            tiers_horse_part: this.getDefaultTierHorsePart(this.form.tiers),
                        })

                        this.load_tiers_options()

                        this.step = 2
                        this.setDisabledButton(false)

                        this.loadingStep1 = false
                    }
                }
            },
            loadTiersCurrentPartComponent: async function(horse_id) {
                // Mets la date du formulaire à la date de dernier mouvement du cheval
                this.getLastMouvement(horse_id)
                .then(mouvement => {
                    if (mouvement && mouvement.mouvement_date && (this.tiers_horse.length == 0 || Date.parseTz(mouvement.mouvement_date) > Date.parseTz(this.tiers_horse[0].tiers_horse_part.tiershorsepart_date)) ) {
                        this.form.date = Date.parseTz(mouvement.mouvement_date)
                    }
                    else if (this.tiers_horse.length == 0) {
                        this.form.date = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                    }
                    else {
                        this.form.date = new Date()
                    }
                })

                return await this.loadTiersCurrentPart(horse_id)
            },
            getDefaultTierHorsePart(tiers_horses) {
                const pcts_left = {
                    frais: "100",
                    pension: "100",
                    propriete: "100",
                    contract: "100",
                    entraineur: "0",
                    jockey: "0"
                }

                tiers_horses.forEach(tier_horse => {
                    pcts_left.frais = pcts_left.frais - tier_horse.tiers_horse_part.tiershorsepart_frais
                    pcts_left.pension = pcts_left.pension - tier_horse.tiers_horse_part.tiershorsepart_pension
                    pcts_left.propriete = pcts_left.propriete - tier_horse.tiers_horse_part.tiershorsepart_propriete
                    pcts_left.contract = pcts_left.contract - tier_horse.tiers_horse_part.tiershorsepart_contract

                })

                return {
                    tiershorsepart_frais: pcts_left.frais > 0 ? pcts_left.frais : "0",
                    tiershorsepart_pension: pcts_left.pension > 0 ? pcts_left.pension : "0",
                    tiershorsepart_propriete: pcts_left.propriete > 0 ? pcts_left.propriete : "0",
                    tiershorsepart_contract: pcts_left.contract > 0 ? pcts_left.contract : "0",
                    tiershorsepart_entraineur: "0",
                    tiershorsepart_jockey: "0"
                }
            },
            checkForm: async function () {
                if(this.ask_confirmation_pension) {
                    this.failureToast("tiers.confirmer_pension")
                    return false
                }
                this.form_message = ""
                let error = []
                let tiers = []
                let part = {
                    frais: 0,
                    pension: 0,
                    propriete: 0,
                    contract: 0,
                    entraineur: 0,
                    jockey: 0
                }

                if(!this.form.horse_id) {
                    error.push("Erreur Horse")
                }

                if(!this.form.date) {
                    error.push("Erreur Date")
                }

                if(!this.form.tiers) {
                    error.push("Erreur Tiers")
                } else {
                    if(this.form.tiers.length > 0) {
                        this.form.tiers.forEach((tier, index) => {
                            if(tier.tiers_horse_part.tiershorsepart_frais.toString().replace(',', '.') >= 0
                                || tier.tiers_horse_part.tiershorsepart_pension.toString().replace(',', '.') >= 0
                                || tier.tiers_horse_part.tiershorsepart_propriete.toString().replace(',', '.') >= 0
                                || tier.tiers_horse_part.tiershorsepart_contract.toString().replace(',', '.') >= 0
                            ) {
                                tiers.push({
                                    tiers_id: tier.tiers.tiers_id,
                                    tiershorse_id: tier.tiershorse_id,
                                    tiershorse_dps: (this.tier_dps == index) ? 1 : 0,
                                    tiershorsepart_frais: tier.tiers_horse_part.tiershorsepart_frais.toString().toFloat(),
                                    tiershorsepart_pension: tier.tiers_horse_part.tiershorsepart_pension.toString().toFloat(),
                                    tiershorsepart_propriete: tier.tiers_horse_part.tiershorsepart_propriete.toString().toFloat(),
                                    tiershorsepart_contract: tier.tiers_horse_part.tiershorsepart_contract.toString().toFloat(),
                                    tiershorsepart_entraineur: tier.tiers_horse_part.tiershorsepart_entraineur.toString().toFloat(),
                                    tiershorsepart_jockey: tier.tiers_horse_part.tiershorsepart_jockey.toString().toFloat(),
                                })

                                let frais_part = tier.tiers_horse_part.tiershorsepart_frais.toString()
                                let pension_part = tier.tiers_horse_part.tiershorsepart_pension.toString()
                                let propriete_part = tier.tiers_horse_part.tiershorsepart_propriete.toString()
                                let contract_part = tier.tiers_horse_part.tiershorsepart_contract.toString()
                                let entraineur_part = tier.tiers_horse_part.tiershorsepart_entraineur.toString()
                                let jockey_part = tier.tiers_horse_part.tiershorsepart_jockey.toString()

                                part.frais += frais_part.toFloat()
                                part.pension += pension_part.toFloat()
                                part.propriete += propriete_part.toFloat()
                                part.contract += contract_part.toFloat()
                                part.entraineur += entraineur_part.toFloat()
                                part.jockey += jockey_part.toFloat()

                                if(this.tier_stationnement == index) {
                                    this.assignLieuStationnement(this.form.horse_id, tier.tiers.tiers_id)
                                }
                            } else {
                                error.push("Erreur Tiers")
                            }
                        })
                        if(!this.tier_stationnement == null) {
                            this.UnassignLieuStationnement(this.form.horse_id)
                        }
                    }

                    if(tiers.length == 0) {
                        error.push("Erreur aucun tiers")
                    }
                }

                if(error.length == 0) {
                    if(Math.round(part.frais) <= 100 && Math.round(part.propriete) <= 100 && Math.round(part.contract) <= 100) {
                        let result = await this.addHorseTiers(
                            this.form.horse_id,
                            this.form.date,
                            tiers
                        );

                        if(result) {
							this.$emit('submit') //pour fiche tier
							this.$emit('horse_id', this.form) //pour contract
                            return true
                        } else {
                            this.form_message = "error.LEP"
                            return false
                        }
                    } else {
                        this.form_message = "tiers.erreur_poucentage"
                        return false
                    }
                } else {
                    this.form_message = "formulaire.erreur_champs_non_remplis"
                    return false
                }
            },
            load_tiers_options() {
                this.tiers_options = []

                let tiers_ls = []
                if (this.form.tiers.length > 0) {
                    tiers_ls = this.form.tiers
                }
                else if (this.tiers_horse) {
                    tiers_ls = this.tiers_horse.filter(tier => !tier.zero)
                }

                this.list_tiers.forEach((tier) => {
                    let add_tier = !tiers_ls.some(tierhorse => tierhorse.tiers.tiers_id == tier.tiers_id)

                    if (add_tier) {
                        this.tiers_options.push({name: tier.tiers_formatted, id: tier.tiers_id })
                    }
                })
            },
            confirmPension() {
                this.ask_confirmation_pension = false
            }
        },
        computed: {
            hasTrainerAccess() {
            	return this.$store.state.userAccess.hasTrainerAccess
            },

            hasFacturationAccess() {
            	return this.$store.state.userAccess.hasFacturationAccess
            },

            hasSaillieAccess() {
            	return this.$store.state.userAccess.hasSaillieAccess
            }
        },
        components: {
            LoadingSpinner,
            ErrorAlert,
            HorseAjout: () => import('@/views/HorseAjout')
        },
        watch: {
            'form.horses'(newval) {
                if(this.addOrEditReady && !this.horse) {
                    this.changeHorse(newval)
                }
            },
            'form.tiers': {
                deep: true,
                handler(val) {
                    //TODO ajout les contrats quand les contrats seront en beta
                    let pension = 0, frais = 0, propriete = 0, contract = 0
                    for(let i in val) {
                        if(!val[i].tiers_horse_part) {
                            continue;
                        }
                        let pension_part = val[i].tiers_horse_part.tiershorsepart_pension.toString()
                        let frais_part = val[i].tiers_horse_part.tiershorsepart_frais.toString()
                        let propriete_part = val[i].tiers_horse_part.tiershorsepart_propriete.toString()
                        let contract_part = val[i].tiers_horse_part.tiershorsepart_contract.toString()

                        pension += pension_part.toFloat()
                        frais += frais_part.toFloat()
                        propriete += propriete_part.toFloat()
                        contract += contract_part.toFloat()
                    }

                    this.form_message = ""
                    this.ask_confirmation_pension = false
                    if((frais != 100 || propriete > 100 || contract > 100) && this.step == 2) {
                        this.form_message = "tiers.erreur_poucentage"
                    }
                    if(pension > 100) {
                        this.ask_confirmation_pension = true
                    }
                }
            },
            horse_id_added() {
                this.init_component()
            }
        }
    }
</script>
